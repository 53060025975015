import { createGlobalStyle } from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

const GlobalStyles = createGlobalStyle`
  :root {
    --white: #ffffff;
    --grey: #efefef;
    --tan: #CF8D4D;
    --brown: #A05C1B;
    --black: #222222;
    --dark: #222222;
  }

  html {
    font-size:10px;
    scrollbar-width: thin;
    scrollbar-color: var(--tan) var(--brown);
  }

  body {
    font-size: 1.6rem;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background: var(--white);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--tan) ;
      border-radius: 6px;
      border: 3px solid var(--brown);
    }
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--brown);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  .bg-dark {
    background-color: var(--black)!important;
  }


`;

export default GlobalStyles;
