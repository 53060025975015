import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import logo from '../assets/images/logo.svg';

const NavStyles = styled.nav`
  margin: 1.5rem 2rem 3rem 1rem;
  position: fixed;
  transition: 0.2s;
  width: calc(100% - 3rem);
  z-index: 2;
  &.scrolled {
    background: var(--black);
  }
  .logo {
    width: 100px;
    &.scrolled {
      width: 50px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
  }

  li {
    display: flex;
    &:nth-child(1) {
      flex: 1;
      padding: 0;
    }
  }
  a {
    font-size: 2rem;
    padding: 1rem;
    text-decoration: none;
    &:hover {
      color: var(--tan);
    }
    &[aria-current='page'] {
      text-decoration: underline;
      color: var(--brown);
    }
  }
`;

export default function Nav() {
  return (
    <NavStyles>
      <ul>
        <li>
          <Link to="/">
            <img src={logo} alt="Rocky Brands Inc." className="logo" />
          </Link>
        </li>
        <li>
          <Link to="/about">Who We Are</Link>
        </li>
        <li>
          <Link to="/brands">Brands</Link>
        </li>
        <li>
          <Link to="/careers">Careers</Link>
        </li>
        <li>
          <Link to="/investors">Investors</Link>
        </li>
        <li>
          <Link to="/contact-us">Contact Us</Link>
        </li>
      </ul>
    </NavStyles>
  );
}
