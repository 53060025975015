import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from './Nav';
import Footer from './Footer';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

const ContentWrapper = styled.div`
  padding: 0;
  margin-top: 16.5rem;
  min-height: calc(100vh - 16.5rem);
  display:flex;
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Row>
        <Col>
          <Nav />
        </Col>
      </Row>
      <ContentWrapper className="wrapper container-fluid">
        <Row>
          <Col>{children}</Col>
        </Row>
      </ContentWrapper>
      <Footer />
    </>
  );
}
