import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-dark p-4 text-white text-center">
      <p>&copy; {new Date().getFullYear()} Rocky Brands Inc. All rights reserved.<br />
        Privacy Policy | Accessibility Notice | Terms &amp; Conditions
      </p>
      <hr className="bg-white"/>
      <p>39 E Canal Street, Nelsonville, Ohio 45764 USA</p>
    </footer>
  );
}
